import React, {Component} from 'react';
import '../css/InfoSection.css'
import Table from "./Table";
import MonthlyCard from "./MonthlyCard";
import CouponCard from "./CouponCard";
import '../css/Loading.css'
import EarningsCard from "./EarningsCard";
import moment from "moment";
import {Link} from "react-router-dom";


class AdminCodeInfo extends Component {

    constructor(props) {
        super(props);

        this.state = {
            dateBreakdown: [],
            show_tooltip: false
        };
        this.fetchDates = this.fetchDates.bind(this);
    }

    async componentDidMount() {
        //first fetch here
        await this.fetchDates();
    }

    async componentDidUpdate(prevProps) {
        //this runs on 2nd menu-choice onwards
        if(this.props.discountCode !== prevProps.discountCode) {
            await this.fetchDates()
        }
    }

    async fetchDates() {
        await this.setState({loading: true});
        //fetch info by code here...
        const response = await fetch('/api/admin-info-by-code', {
            method: 'POST',
            headers: {"Content-Type": "text/plain"},
            body: this.props.discountCode
        });

        const data = await response.json();
        await this.setState({
            deviceCodeCount: data.device_count,
            sugarCodeCount: data.sugar_count,
            discountType: data.type,
            discountAmount: data.amount,
            name: data.name,
            currency: data.currency,
            commission:data.commission,
            token: data.token,
            dateBreakdown: data.date_breakdown,
            monthlyEarnings: data.monthly_earnings
        });
        await this.setState({loading: false});
    }

    render() {
        let affiliateLink;
        let affiliateHeading;
        if (this.state.token) {
            affiliateLink = "affiliate.foodmarble.com/" + this.state.token;
            affiliateHeading = affiliateLink + '    ' + String.fromCharCode(10697);
        } else {
            affiliateHeading = '';
        }


        let monthCard;

        if(this.state.monthlyEarnings  && this.state.monthlyEarnings.length) {
            monthCard = <MonthlyCard
                monthlyEarnings={this.state.monthlyEarnings}
                currency={this.state.currency}/>
        } else {
            monthCard = <MonthlyCard/>
        }

        let earningsCard;
        if(this.state.monthlyEarnings  && this.state.monthlyEarnings.length) {
            let thisMonthEarnings;
            if (this.state.monthlyEarnings.length > 0) {
                //Check if the most recent month listed in the monthlyEarnings object is the current month
                if (this.state.monthlyEarnings[0]['month'] === moment().format('MMM-YY').toLocaleUpperCase()) {
                    thisMonthEarnings = this.state.monthlyEarnings[0]['earning']
                } else {
                    thisMonthEarnings = 0;
                }
            } else {
                thisMonthEarnings = 0;
            }

            let earningsSum = 0;
            for (let i = 0; i < this.state.monthlyEarnings.length; i++) {
                earningsSum += this.state.monthlyEarnings[i]['earning'];
            }

            earningsCard = <EarningsCard
                currentMonthEarnings={ thisMonthEarnings.toLocaleString('en', {maximumFractionDigits: 2, minimumFractionDigits: 2}) }
                totalEarnings = { earningsSum.toLocaleString('en', {maximumFractionDigits: 2, minimumFractionDigits: 2}) }
                currency={ this.state.currency }
                commission={ this.state.commission }
            />;
        } else {
            monthCard = <Table dateBreakdown={this.state.dateBreakdown} currency={this.state.currency}/>
        }

        return (
            <div className="infosection">
                <div className="lds-roller" style={this.state.loading ? {display: 'block'} : {display: 'none'}}>
                    <div/><div/><div/><div/><div/><div/><div/><div/>
                </div>
                <div className="headings">
                    <h2>{ this.state.name !== undefined ? this.state.name.toUpperCase() : '' }</h2>
                    <h1 onClick={async () => {
                        await navigator.clipboard.writeText(affiliateLink);
                        await this.setState({show_tooltip: true});
                        setTimeout(() => this.setState({show_tooltip: false}), 750)}
                    }>{affiliateHeading}</h1>
                    <p className={this.state.show_tooltip ? "tooltip-vis" : "tooltip-invis"} >Copied to Clipboard!</p>
                </div>
                <CouponCard
                    discountCode={ this.props.discountCode }
                    deviceCodeCount={ this.state.deviceCodeCount }
                    sugarCodeCount={ this.state.sugarCodeCount }
                    discountType={ this.state.discountType }
                    discountAmount={ this.state.discountAmount }
                    currency={ this.state.currency }
                />

                {earningsCard}
                <div className='container'>
                    {monthCard}
                </div>
            </div>
        );
    }
}

export default AdminCodeInfo;