import React, { Component } from 'react';

class Logo extends Component {
    render () {
        return(
            <div className={ this.props.className }>
                <img
                    className={ "logo-img" }
                    src={ require('../assets/logo-white.png') }
                    alt={ "logo" }
                />
            </div>
        );
    }
}

export default Logo;