import React, { useContext } from 'react';
import '../css/SignIn.css'
import { useForm } from "react-hook-form";
import { login, LoginContext } from '../contexts/LoginContext';

const SignIn = () => {
    const { dispatch } = useContext(LoginContext);

    const { register, handleSubmit } = useForm({
        defaultValues: {
            email: "",
            password: "",
        }
    });

    const onSubmit = async ({email,password}) => {
        const res = await login(email,password);
        dispatch({type: "SET_LOGIN_TOKEN", payload: res.success ? res.token : undefined });
    }

    return (
        <div className={'sign-in-container'}>
            <div className={'sign-in-form'}>
                <h3>Sign In</h3>
                <div className={'inputs'}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <label>Email</label>
                        <input type="email" autoComplete="username" {...register("email", { required: "Email is required" })}/>
                        <label>Password</label>
                        <input type="password" autoComplete="current-password" {...register("password", { required: "Password is required" }) }/>
                        {/*<input type={'text'} placeholder={'input'}/>
                        <label>Text</label>*/}
                        <button type="submit">Log In</button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default SignIn;