import React, {Component} from 'react';
import AdminCodeInfo from "./AdminCodeInfo";

/*
* NB: the need for both AdminCodeProfile and AdminCodeInfo is due to update not being triggered by 'match' prop-change
* (from react-router-dom) despite change taking place. Hence, we make our own trigger by simply passing prop-to-change
* to a child component.
* */

//TODO: delete this before pushing

class AdminCodeProfile extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <AdminCodeInfo discountCode={this.props.match.params.discount_code}/>
            </div>
        );
    }
}

export default AdminCodeProfile;