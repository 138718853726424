import React from 'react';
import InfoSection from './components/InfoSection'
import './App.css';
import SideBar from "./components/SideBar";
import ErrorPage from "./components/ErrorPage";
import SignIn from "./components/SignIn";
import AdminHome from "./components/AdminHome";
import {Route, withRouter} from "react-router-dom";
import AdminSideBar from "./components/AdminSideBar";
import AdminCodeProfile from "./components/AdminCodeProfile";
import AdminTopCodes from "./components/AdminTopCodes";
import AddDiscountCode from './components/AddDiscountCode/AddDiscountCode';
import { LoginContext } from './contexts/LoginContext';


//TODO: refactor state throughout App when refactoring server functions
// Version 1.1


class App extends React.Component {
  static contextType = LoginContext;

  constructor(props) {
    super(props);
      this.state = {
          origin: window.location.origin,
          token: window.location.pathname.substring(1),
          first_name: "",
          currency: "",
          commission: "",
          discount: {
              valid: "",
              discount_code: "",
              device_code_count: "",
              date_breakdown: "",
              discount_type: "",
              discount_amount: "",
              monthly_earnings: "",
          }
      };
  }

  /******************* Lifecycle Methods **********************/

  async componentDidMount() {
    let response;
    let data;

    if(!this.state.token.startsWith('admin') && this.state.token !== '') {
        try {
            response = await fetch("/api/verify", {       //NOTE: proxy in client/package.json will forward to correct port in development (3000 -> 8000)
                method: "POST",
                headers: {"Content-Type": "text/plain"},
                body: this.state.token
            });
            data = await response.json();

        } catch (e) {
            alert(e)
        }

        if(data.valid) {
            this.setState(
                {
                    first_name: data.first_name,
                    currency: data.currency,
                    commission: data.commission,
                    discount:
                        {
                            valid: data.valid,
                            discount_code: data.discount_code,
                            device_code_count: data.device_code_count,
                            sugar_code_count: data.sugar_code_count,
                            date_breakdown: data.date_breakdown,
                            discount_type: data.discount_type,
                            discount_amount: data.discount_amount,
                            monthly_earnings: data.monthly_earnings
                        }
                });
        } else {
            this.setState({
                discount: {
                    valid: data.valid,
                }
            });
        }
    }
  }

    /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
    * 3 different return values to handle false links, good links and pending responses from server
    * (first render will be blank since request made in the ComponentDidMount() lifecycle method, hence
    * the need for a blank page to 'flash' as opposed to error-page)
    * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

    //TODO: fix up passing of props here

  render() {
      if(this.state.discount.valid === false) {
          return(
                <div className={"App-invalid"}>
                    <ErrorPage />
                </div>
          );
      } else if (this.state.discount.valid === true) {
          return (
              <div className={"App"}>
                  <SideBar className={"sidebar"} />
                  <InfoSection
                      className={ "infosection" }
                      discountCode={ this.state.discount.discount_code }
                      deviceCodeCount={ this.state.discount.device_code_count }
                      sugarCodeCount={ this.state.discount.sugar_code_count}
                      dateBreakdown={ this.state.discount.date_breakdown }
                      discountType={ this.state.discount.discount_type }
                      discountAmount={ this.state.discount.discount_amount }
                      firstName={ this.state.first_name }
                      currency={ this.state.currency }
                      commission={ this.state.commission }
                      monthlyEarnings={this.state.discount.monthly_earnings}
                  />
              </div>
          );
          //todo: this whole component can be simplified now router is implemented
      } else if(this.state.token.startsWith("admin")) {
          return (
              <div className="App">
                  <Route path={'/admin'} component={this.context.state.loginSuccess ? ProtectedRoutes : SignIn}/>
              </div>
          );
      } else {
          return (
              <div className={"App-blank"}></div>
          );
      }
  }
}

const ProtectedRoutes = ({match}) => {
    return (
        <div>
            <div className={'display'}>
				<Route exact path={'/admin/codes/:discount_code'} component={AdminCodeProfile} match={match}/>
                <Route exact path={'/admin'} component={AdminHome} match={match}/>
                <Route exact path={'/admin/top'} component={AdminTopCodes} match={match}/>
				<Route exact path={'/admin/add'} component={AddDiscountCode} match={match} />
            </div>
            <AdminSideBar className={'admin-sidebar'}/>
        </div>
    );
};


export default withRouter(App);