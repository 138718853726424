import React, { useEffect, useReducer } from "react";
import { createContext } from "react";

const LOGIN_TOKEN_ITEM = "login-token";

type User = [{
    who_id: number,
    email: string,
    firstName: string,
    priv_level: number,
}]

interface LoginContextState {
    loginSuccess: boolean;
    loginToken: string | undefined;
    user: User | undefined;
}

export type LoginContextAction = 
  | { type: "SET_LOGIN_TOKEN", payload: string | undefined, } 
  | { type: "SET_LOGIN_SUCCESS", payload: boolean, } 
  | { type: "LOG_IN", payload: { user: User }, } 
  | { type: "LOG_OUT" };

const reducer = (state: LoginContextState, action: LoginContextAction): LoginContextState => {
    switch (action.type) {
        case "SET_LOGIN_TOKEN": {
            const loginToken = action.payload;
            if (loginToken) {
                window.localStorage.setItem(LOGIN_TOKEN_ITEM,loginToken);
            } else {
                window.localStorage.removeItem(LOGIN_TOKEN_ITEM);
            }
            return { ...state, loginToken: action.payload }
        }
        case "SET_LOGIN_SUCCESS": {
            return { ...state, loginSuccess: action.payload }
        }
        case "LOG_IN": {
            const { user } = action.payload;
            return { ...state, loginSuccess: true, user, }
        }
        case "LOG_OUT": {
            window.localStorage.removeItem(LOGIN_TOKEN_ITEM);
            return {
                loginToken: undefined,
                loginSuccess: false,
                user: undefined,
            }
        }
    }
}

interface LoginContextType {
    state: LoginContextState;
    dispatch: React.Dispatch<LoginContextAction>;
}

const initialState: LoginContextState = {
    loginSuccess: false,
    loginToken: undefined,
    user: undefined,
}

export const LoginContext = createContext<LoginContextType>({
    state: initialState,
    dispatch: () => {},
});

export const LoginContextProvider = ({children}: {children: React.ReactNode}) => {
    const [state,dispatch] = useReducer<React.Reducer<LoginContextState,LoginContextAction>>(reducer, initialState);

    useEffect(() => {
        const token = window.localStorage.getItem(LOGIN_TOKEN_ITEM);

        if (token) {
            dispatch({type: "SET_LOGIN_TOKEN", payload: token});
            return;
        }
        dispatch({type: "SET_LOGIN_SUCCESS", payload: false});
    },[]);

    useEffect(() => {
        if (!state.loginToken) return;
        verifyLoginToken(state.loginToken).then(res => {
            if (!res.success) {
                console.error(res.authStatus);
                dispatch({type: "SET_LOGIN_SUCCESS", payload: false});
                return;
            }
            dispatch({type: "LOG_IN", payload: { user: res.user } });
        });
    },[state.loginToken]);

    return <LoginContext.Provider value={{state,dispatch}}>{children}</LoginContext.Provider>
}

type TokenResponse = {
    success: false,
    authStatus: string,
} | {
    success: true,
    authStatus: string,
    token: string,
    user: User,
}

export const verifyLoginToken = async (token: string): Promise<TokenResponse> => {
    const res = await fetch(`/verify-token`,{
        method: "POST",
        body: token,
    });
    return await res.json();
}

export const login = async (email: string, password: string): Promise<TokenResponse> => {
    const res = await fetch(`/login`, {
        method: "POST",
        body: JSON.stringify({
            email,
            password,
        })
    });
    const result = await res.json();
    return result;
}