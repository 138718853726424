import React, {Component} from 'react';
import {Link} from "react-router-dom";
import '../css/AdminHome.css'
import { LoginContext } from '../contexts/LoginContext';

class AdminTopCodes extends Component {
    static contextType = LoginContext;

    constructor(props) {
        super(props);

        this.state={
            top_codes_all_time: [],
            top_codes_week: [],
        }
    }

    async componentDidMount() {
        //get all affiliates for the two tables (best of all time etc)
        const response = await fetch('/api/top-codes', {
            method: 'POST',
            headers: {"Content-Type": "text/plain"},
            body: this.context.state.loginToken,
        });

        const data = await response.json();

        await this.setState({
            top_codes_all_time: data.all_time,
            top_codes_week: data.seven_days
        })
    }

    render() {
        if(this.state.top_codes_all_time !== [] && this.state.top_codes_week !== []) {
            const allTimeRows = this.state.top_codes_all_time.map((row, index) =>
                <tr key={index}>
                    <td><Link to={`/admin/codes/${row.discount_code}`} className="table-discount-code">{row.discount_code}</Link></td>
                    <td>{row.description}</td>
                    <td>{row.amount}</td>
                    <td>{row.expiry}</td>
                    <td>{row.count}</td>

                </tr>
            );
            const weekRows = this.state.top_codes_week.map((row, index) =>
                <tr key={index}>
                    <td><Link to={`/admin/codes/${row.discount_code}`} className="table-discount-code">{row.discount_code}</Link></td>
                    <td>{row.description}</td>
                    <td>{row.amount}</td>
                    <td>{row.expiry}</td>
                    <td>{row.count}</td>
                </tr>
            );
            return (
                <div className='admin-infosection'>
                    <div className='container'>
                        <table>
                            <caption>Top Codes This Week</caption>
                            <tbody>
                            <tr>
                                <th>Code</th>
                                <th>Label</th>
                                <th>Amount</th>
                                <th>Expiry</th>
                                <th>Count</th>
                            </tr>
                            {weekRows}
                            </tbody>
                        </table>
                    </div>
                    <div className='container'>
                        <table>
                            <caption>Top Codes of All Time</caption>
                            <tbody>
                            <tr>
                                <th>Code</th>
                                <th>Label</th>
                                <th>Amount</th>
                                <th>Expiry</th>
                                <th>Count</th>
                            </tr>
                            {allTimeRows}
                            </tbody>
                        </table>
                    </div>
                </div>
            );
        } else {
            return (
                <div>EMPTY TABLE HERE</div>
            );
        }
    }
}

export default AdminTopCodes;