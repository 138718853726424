import React, { Component, useContext } from 'react';
import {Link, withRouter} from "react-router-dom";
import Logo from './Logo';
import '../css/AdminSideBar.css';
import { LoginContext } from '../contexts/LoginContext';

import HomeLogo from '../assets/home.svg';
import TrophyLogo from '../assets/trophy_new.png';
import CouponLogo from "../assets/coupon.svg";
import LogoutLogo from "../assets/logout.svg";

class AdminSideBar extends Component {
    constructor(props) {
        super(props);
        this.state={
            search_input: '',
            search_results: [],
            show_search: false
        };
        this.handleSearchInput=this.handleSearchInput.bind(this)
    }

    async handleSearchInput(e) {
        const search_input = e.target.value;

        if(search_input.length >= 2) {
            await this.setState({
                search_input: search_input
            });

            //perform fetch, then pass to render to generate li's
            const response = await fetch('/api/search-aff-or-code', {
                method: 'POST',
                headers: {"Content-Type": "text/plain"},
                body: this.state.search_input
            });

            const data = await response.json();
            await this.setState({
                search_results: data
            })
        }
    }

    render () {

        const sidebarResults = this.state.search_results.map((result, index) =>
            <li key={index} className="result-li">
                <Link to={`/admin/codes/${result.discount_code}`} onClick={async () => {await this.setState({show_search: false})}}>
                    {result.name == null ? '' : result.name}
                    <br></br>
                    {result.discount_code}
                    {' - ' + result.amount}
                    <span>{' (Expires ' + result.expiry + ')'}</span>
                </Link>
            </li>
        );

        return (
            <nav className={ "menu" }>
                <div className={ this.props.className }>
                    <Logo
                        { ...this.props }
                        className={ "logo" }
                    />
                    <ul className="menu-ul">
                        <li className="first-li"><Link to='/admin'><img className={"home-logo"} src={ HomeLogo } alt="Home Icon" /><span className="menu-label">Home</span></Link></li>
                        <li className="second-li"><Link to='/admin/top'><img className={"top-logo"} src={ TrophyLogo } alt="Trophy Icon"/><span className="menu-label">Top Codes</span></Link></li>
                        <li><Link to="/admin/add"><img className="discount-code-logo" src={CouponLogo} /><span className="menu-label">Add Discount Code</span></Link></li>
                        <li className="third-li"><button className={'menu-button'} onClick={async () => {await this.setState({show_search: !this.state.show_search})}}><img className="home-logo" src={require('../assets/search.svg')} alt="Search Icon"/><span className="menu-label">Search</span></button></li>
                        <li><input type='search' className='searchbar' placeholder='Search...' style={this.state.show_search ? {display: 'block'} : {display: 'none'}} onChange={this.handleSearchInput} /></li>
                        <div className="search-results-background" style={this.state.show_search ? {display: 'block'} : {display: 'none'}}/>
                        <div className="search-results-container" style={this.state.show_search ? {display: 'block'} : {display: 'none'}}>
                            <ul className="results-list">
                                {sidebarResults}
                            </ul>
                        </div>
                        <LogoutButtonLi />
                    </ul>
                    <button className="exit-mob-search" onClick={async () => {await this.setState({show_search: false})}} style={this.state.show_search ? {display: 'block'} : {display: 'none'}}>X</button>
                </div>
            </nav>
        );
    }
}

const LogoutButtonLi = () => {
    const { dispatch } = useContext(LoginContext);
    return (
        <li className="logout-button-li" onClick={() => dispatch({type: "LOG_OUT"})}>
            <img src={LogoutLogo} />
            <span>Log Out</span>
        </li>
    )
}

export default withRouter(AdminSideBar);