import React from "react";
import ReactModal from "react-modal";
import s from "./AddDiscountCode.module.scss";
import { Affiliate } from "./affiliate";
import CopyIcon from "../../assets/copy-icon.svg";

const MODAL_STYLE: ReactModal.Styles = {
    overlay: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
    content: {
        left: "20%",
        right: "20%",
        marginTop: "10px",
        marginBottom: "10px",
        top: "auto",
        bottom: "auto",
        maxHeight: "97vh",
        borderRadius: "15px",
        boxShadow: "0 10px 30px 0 rgba(0, 0, 0, 0.15)",
        border: "none",
    }
};

export default function AddDiscountCodeSuccess({ discountCode, affiliate, complete, setComplete }: SuccessProps) {
    const dashUrl = affiliate ? affiliateDashUrl(affiliate) : undefined;
    const shopUrl = discountCode ? shopDiscountUrl(discountCode) : undefined;

    return (
        <ReactModal isOpen={complete} onRequestClose={() => setComplete(false)} style={MODAL_STYLE}>
            <div className={s.successModal}>
                <h1>Discount Code Created Successfully</h1>
                {affiliate && discountCode && (
                    <div>
                        {dashUrl && <SuccessLinkRow url={dashUrl}>Dashboard link:</SuccessLinkRow>}
                        {shopUrl && <SuccessLinkRow url={shopUrl}>Shop link:</SuccessLinkRow>}
                    </div>
                )}
                <button type="button" className={s.successReturnBtn} onClick={() => setComplete(false)}>Return</button>
            </div>
        </ReactModal>
    );
};

interface SuccessProps {
    discountCode: string;
    affiliate: Affiliate | undefined;
    complete: boolean;
    setComplete: React.Dispatch<React.SetStateAction<boolean>>;
}

const SuccessLinkRow = ({ url, children }: { url: URL; children?: React.ReactNode; }) => {
    return (
        <div>
            <span>{children}</span>
            <span><a href={url.toString()}>{url.toString()}</a></span>
            <span><button className={s.copyBtn} type="button" onClick={() => copyToClipboard(url.toString())}><img src={CopyIcon} /></button></span>
        </div>
    );
};

const copyToClipboard = (text: string) => navigator.clipboard.writeText(text);

const affiliateDashUrl = (affiliate: Affiliate): URL | undefined => {
    return affiliate.token ? new URL(`https://affiliate.foodmarble.com/${affiliate.token}`) : undefined;
};

const shopDiscountUrl = (discountCode: string): URL => {
    const base = `https://${process.env.NODE_ENV !== "development" ? "foodmarble.com" : "test.foodmarble.com"}`;
    return new URL(`${base}/shop/?discount_code=${discountCode}`);
};
