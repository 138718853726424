import React, { Component } from 'react';
import '../css/Cards.css';

const currencies = {
    EUR: '€',
    USD: '$',
    GBP: '£',
    AUD: '$',
    CAD: '$',
    NZD: '$'
};

const curr_to_symbol = (curr) => {
    if (curr) {
        const symbol = currencies[curr];
        return symbol
    }
};

class CouponCard extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let type;

        if (this.props.discountType) {          //what 'grey-text' to display when data is received

            if (this.props.discountType === 'abs' && this.props.discountAmount !== 0) {
                type = <h5>Gives users {curr_to_symbol(this.props.currency)}{this.props.discountAmount} discount on our website</h5>
            } else if (this.props.discountType === 'perc' && this.props.discountAmount !== 0) {
                let pct = parseFloat(this.props.discountAmount) * 100;
                type = <h5>Gives users {pct.toFixed(0)}% discount on our website</h5>
            } else {
                type = <h5>Gives users discount on our website</h5>
            }
        } else {
            type = <h5>Gives users discount on our website</h5>
        }

        //TODO: clear unnecessary divs
        return (
            <div className={"coupon-card"}>
                <div className={"code-container"}>
                    <div className={"code-text"}>
                        <h3 style={{padding: "0px"}}>DISCOUNT CODE:</h3>
                    </div>
                    <div id={"discount-code"}>
                        <span className={'coupon-code'}>{this.props.discountCode}</span>
                        {type}
                    </div>
                </div>
                <hr />
                <h3 className={"device-uses"}>DEVICES {<br />} SOLD:</h3>
                <h6>{this.props.deviceCodeCount || 0}</h6>
                <h3 className={"sugar-uses"}>FODMAPS {<br />} SOLD:</h3>
                <h6>{this.props.sugarCodeCount || 0}</h6>
                <p>(These figures refer to the total amount of each sold, including device-FODMAP bundles)</p>
            </div>
        );
    }
}

export default CouponCard