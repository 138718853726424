import React, { Component } from 'react';
import '../css/Cards.css';

const currencies = {
    EUR: '€',
    USD: '$',
    GBP: '£',
    AUD: '$',
    CAD: '$',
    NZD: '$'
};

const curr_to_symbol = (curr) => {
    if(curr) {
        const symbol = currencies[curr];
        return symbol
    }
};

class EarningsCard extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        return(
            <div className={ "earnings-card" }>
                <h5 className="commission-text">Commission: {(this.props.commission * 100).toFixed(0)}% of sales value</h5>
                <h3 className={ "month-earnings" }>EARNED {<br />} THIS MONTH:</h3>
                <h6 style={{float: "right"}}>{curr_to_symbol(this.props.currency)}{this.props.currentMonthEarnings || 0}</h6>
                <hr />
                <h3 className={ "total-earnings" }>TOTAL {<br />} EARNED:</h3>
                <h6 style={{float: "right"}}>{curr_to_symbol(this.props.currency)}{this.props.totalEarnings || 0}</h6>
            </div>
        );
    }
}

export default EarningsCard