import React, { Component } from 'react';
import '../css/Table.css';
import moment from 'moment';

class Table extends Component {
    render() {

        //TODO: insert something a bit more thoughtful for empty table

        if(this.props.dateBreakdown) {
            const rows = this.props.dateBreakdown.map((dates, index) =>
                <tr key={index}>
                    <td>{moment(dates.date).format('DD-MM-YYYY')}</td>
                    <td className="align-right">{dates.count}</td>
                    <td className="align-right">€{ dates.total.toLocaleString('en', {maximumFractionDigits: 2, minimumFractionDigits: 2}) }</td>
                    {/* <td>{dates.total}</td> */}
                </tr>
            );

            return(
                <div className={"date-table"}>
                    <table>
                        <caption>Sales Breakdown</caption>
                        <tbody>
                            <tr>
                                <th>DATE</th>
                                <th>COUNT</th>
                                <th>AMOUNT</th>
                            </tr>
                            {rows}
                        </tbody>
                    </table>
                </div>
            );
        } else {
            return(
                <div className={"date-table"}>
                    <h3>{'EMPTY TABLE'}</h3>
                </div>
            );
        }
    }
}

export default Table;
