import React, {Component} from 'react';
import {Link} from "react-router-dom";
import '../css/AdminHome.css'
import { LoginContext } from '../contexts/LoginContext';

class AdminHome extends Component {
    static contextType = LoginContext;

    constructor(props) {
        super(props);

        this.state={
            active_codes: []
        }
    }

    async componentDidMount() {
        //get all affiliates for the two tables (best of all time etc)
        const response = await fetch('/api/active-codes', {
            method: 'POST',
            headers: {"Content-Type": "text/plain"},
            body: this.context.state.loginToken,
        });

        const data = await response.json();

        await this.setState({
            active_codes: data.active_codes
        })
    }

    render() {
        if(this.state.active_codes !== [] && this.state.active_codes !== []) {
            const activeCodeRows = this.state.active_codes.map((row, index) =>
                <tr key={index}>
                    <td><Link to={`/admin/codes/${row.discount_code}`} className="table-discount-code">{row.discount_code}</Link></td>
                    <td>{row.description}</td>
                    <td>{row.amount}</td>
                    <td>{row.expiry}</td>
                    <td>{row.count}</td>
                </tr>
            );

            return (
                <div className='admin-infosection'>
                    <div className='container'>
                        <table>
                            <caption>Current Active Codes</caption>
                            <tbody>
                                <tr>
                                    <th>Code</th>
                                    <th>Label</th>
                                    <th>Amount</th>
                                    <th>Expiry</th>
                                    <th>Count</th>
                                </tr>
                                {activeCodeRows}
                            </tbody>
                        </table>
                    </div>
                </div>
            );
        } else {
            return (
                <div>EMPTY TABLE HERE</div>
            );
        }
    }
}

export default AdminHome;