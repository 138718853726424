import React, { Component } from 'react';
import '../css/Table.css';

const currencies = {
    EUR: '€',
    USD: '$',
    GBP: '£',
    AUD: '$',
    CAD: '$',
    NZD: '$'
};

const curr_to_symbol = (curr) => {
    if(curr) {
        const symbol = currencies[curr];
        return symbol
    }
};

class MonthlyCard extends Component {
    render() {

        if(this.props.monthlyEarnings.length > 0) {
            const rows = this.props.monthlyEarnings.map((months, index) =>
                <tr key={index}>
                    <td>{months.month}</td>
                    <td className='align-right'>{curr_to_symbol(this.props.currency)}{months.value.toLocaleString('en', {maximumFractionDigits: 2, minimumFractionDigits: 2})}</td>
                    <td className='align-right'>{curr_to_symbol(this.props.currency)}{months.earning.toLocaleString('en', {maximumFractionDigits: 2, minimumFractionDigits: 2})}</td>
                </tr>
            );

            return(
                <div className="monthly-card">
                    <table>
                        <caption>Monthly Earnings Breakdown</caption>
                        <tbody>
                            <tr>
                                <th>MONTH</th>
                                <th>SALES VALUE</th>
                                <th>EARNING</th>
                            </tr>
                            {rows}
                        </tbody>
                    </table>
                </div>
            );
        } else {
            return(
                <div className="monthly-card">
                    <h3>NO SALES ON THIS CONTRACT YET!</h3>
                    <h5>This table will break down your earnings per month once customers start using your discount code!</h5>
                </div>
            );
        }
    }
}

export default MonthlyCard;
