import React, { Component } from 'react';

//TODO: more imaginative error page

class ErrorPage extends Component {

    render() {
        return(
            <div>
                <h1>Error!</h1>
                <h2>Invalid Link</h2>
            </div>
        );
    }
}

export default ErrorPage