import React, { Component } from 'react';
import Logo from './Logo';
import '../css/SideBar.css';

class SideBar extends Component {
    render () {
        return (
            <nav className={ "menu" }>
                <div className={ this.props.className }>
                    <Logo
                        { ...this.props }
                        className={ "logo" }
                    />
                    <ul>
                        <li className="second-li"><a href={"#"}><img className={"home-logo"} src={ require('../assets/home.svg')} alt="Home Icon" /><span>Home</span></a></li>
                        <li className="third-li"><a href="https://www.foodmarble.com/"><img className={"web-logo"} src={ require('../assets/web.svg')} alt="Web Icon"/><span>Website</span></a></li>
                    </ul>
                </div>
            </nav>
        );
    }
}

export default SideBar;