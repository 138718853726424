/**
 * A modified console.log (with timestamps) that only logs in development
 * Use this function for logs that you want to keep in the code when pushing to repo
 */
export const devLog = (...log: any[]) => {
    // NODE_ENV is a built-in env variable: https://create-react-app.dev/docs/adding-custom-environment-variables/
    if (process.env.NODE_ENV !== "development") return;

    console.log(
      "\x1B[34m%s\x1b[0m",
      Date.now().toString().slice(-5) + ":",
      ...log
    );
};

export function logAndFetch(input: RequestInfo, init?: RequestInit) {
    const method = init && init.method ? init.method : "GET";
    devLog(`${method}: ${input}`);
    return fetch(input,init);
};