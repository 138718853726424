import { useEffect, useState } from "react"

interface DiscountCampaignBackend {
    cam_id: number,
    created_on_ms: number | null,
    label: string | null,
    description: string | null,
    sku_id: number | null, 
    type: "perc" | "abs" | null,
    amount: string | null,
    is_clinical: boolean | null,
}

export interface DiscountCampaign {
    camId: number,
    createdOnMs: number | undefined,
    label: string | undefined,
    description: string | undefined,
    skuId: number | undefined, 
    type: "perc" | "abs" | undefined,
    amount: number | undefined,
    isClinical: boolean | undefined,
}

const useCampaigns = () => {
    const [result, setResult] = useState<DiscountCampaign[]>();

    useEffect(() => {
        if (result) return;
        fetchDiscountCampaigns().then(campaigns => setResult(campaigns.filter(c => c.type === "perc").sort(compareDiscountCampaigns)));
    }, []);

    return result;
}

async function fetchDiscountCampaigns(): Promise<DiscountCampaign[]> {
    try {
        const res = await fetch(`/api/discount-campaigns/all`);
        const data: DiscountCampaignBackend[] = await res.json();
        return data.map(parseDiscountCampaign);
    } catch {
        return [];
    }
}

const parseDiscountCampaign = ({cam_id,amount,created_on_ms,description,is_clinical,label,sku_id,type}: DiscountCampaignBackend) => ({
    camId: cam_id,
    amount: amount ? parseFloat(amount) : undefined,
    createdOnMs: created_on_ms ? created_on_ms : undefined,
    description: description ? description : undefined,
    isClinical: is_clinical ? is_clinical : undefined,
    label: label ? label : undefined,
    skuId: sku_id ? sku_id : undefined,
    type: type ? type : undefined,
});

enum CampaignSplit {
    DATE="date",
    ALPH="alph",
    UNDEFINED_LABEL="undefined label",
    NO_CAMPAIGN="No Campaign",
}

const compareDiscountCampaigns = (a: DiscountCampaign, b: DiscountCampaign) => {
    if (getCampaignPriority(a) < getCampaignPriority(b)) return -1;
    if (a.label === undefined || b.label === undefined) return 0;
    if (a.label === CampaignSplit.NO_CAMPAIGN || b.label === CampaignSplit.NO_CAMPAIGN) {
        return (a.label === CampaignSplit.NO_CAMPAIGN && -1) || (b.label === CampaignSplit.NO_CAMPAIGN && 1) || 0;
    }
    if (getCampaignSplit(a) === CampaignSplit.ALPH) return a.label < b.label ? -1 : 1;
    return a.label < b.label ? 1 : -1;;
}

const getCampaignSplit = (campaign: DiscountCampaign): CampaignSplit => {
    if (campaign.label === undefined) return CampaignSplit.UNDEFINED_LABEL;
    if (campaign.label === CampaignSplit.NO_CAMPAIGN) return CampaignSplit.NO_CAMPAIGN;
    if (campaign.label.match(/^[0-9]...-[0-9]./)) return CampaignSplit.DATE;
    return CampaignSplit.ALPH;
}

const splitPriority: Record<CampaignSplit,number> = {
    "No Campaign": 1,
    "alph": 2,
    "date": 3,
    "undefined label": 4,
}

const getCampaignPriority = (campaign: DiscountCampaign) => splitPriority[getCampaignSplit(campaign)];


export default useCampaigns;