import React, {Component} from 'react';
import '../css/InfoSection.css';
import CouponCard from "./CouponCard";
import EarningsCard from "./EarningsCard";
import moment from 'moment';
import MonthlyCard from "./MonthlyCard";

class InfoSection extends Component {
    render() {
        let thisMonthEarnings;
        //Check that at least 1 sale has been made with this discount code
        if ((this.props.monthlyEarnings).length  > 0) {
            //Check if the most recent month listed in the monthlyEarnings object is the current month
            if (this.props.monthlyEarnings[0]['month'] === moment().format('MMM-YY').toLocaleUpperCase()) {
                thisMonthEarnings = this.props.monthlyEarnings[0]['earning']
            } else {
                thisMonthEarnings = 0;
            }
        } else {
            thisMonthEarnings = 0;
        }

        let earningsSum = 0;
        for (let i = 0; i < this.props.monthlyEarnings.length; i++) {
            earningsSum += this.props.monthlyEarnings[i]['earning'];
        }

        let monthCard;
        if (this.props.monthlyEarnings) {
            monthCard = <MonthlyCard
                monthlyEarnings={this.props.monthlyEarnings}
                currency={this.props.currency}/>

        } else {
            monthCard = <MonthlyCard/>
        }

        return (
            <div className={this.props.className}>
                <div className={"headings"}>
                    <h2>HELLO, {this.props.firstName}</h2>
                    <h1>{moment().format('MMMM Do YYYY')}</h1>
                </div>
                <CouponCard
                    discountCode={this.props.discountCode}
                    deviceCodeCount={this.props.deviceCodeCount}
                    sugarCodeCount={this.props.sugarCodeCount}
                    discountType={this.props.discountType}
                    discountAmount={this.props.discountAmount}
                    currency={this.props.currency}
                />

                <EarningsCard
                    currentMonthEarnings={thisMonthEarnings.toLocaleString('en', {maximumFractionDigits: 2, minimumFractionDigits: 2})}
                    totalEarnings={earningsSum.toLocaleString('en', {maximumFractionDigits: 2, minimumFractionDigits: 2})}
                    currency={this.props.currency}
                    commission={this.props.commission}
                />
                <div className='container'>
                    {monthCard}
                </div>
            </div>
        );
    }
}

export default InfoSection